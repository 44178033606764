import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
} from "@practice/sdk";
import { round } from "lodash";
import { DateTime, Interval } from "luxon";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageItemsType } from "@lib/data/schemas/packages";

/**
 * Checks if the package instance is cycle based
 * */
export const isCycleBased = (
  packageType?: PackageInstanceType["packageType"]
) => ["recurring", "usage"].includes(packageType ?? "");

/**
 * Returns a rounded number from invoice items
 * */
type PackageItemsWithUnits = PackageItemsType & {
  unitAmount: number;
  itemAmount: number;
};
export const getInvoiceItems = (items: PackageItemsWithUnits[]) => {
  const invoiceItems = items?.map((item) => {
    const items = [];
    const pseudoTotal = item.quantity * item.unitAmount;

    const difference = round(item.itemAmount - pseudoTotal, 2);
    if (difference === 0) {
      items.push(item);
    } else {
      if (item.quantity !== 1)
        items.push({
          ...item,
          quantity: item.quantity - 1,
          itemAmount: (item.quantity - 1) * item.unitAmount,
        });

      const remaining = round(item.unitAmount + difference, 0);

      items.push({
        ...item,
        quantity: 1,
        unitAmount: remaining,
        itemAmount: remaining,
      });
    }

    return items;
  });
  return invoiceItems.flat();
};

/**
/**
 * Get the furthest appointment date from a list of appointments.
 * It's used mainly to calculate the total cycles in a package instance,
 * specially when there are appointments in the future.
 * */
export const getFurthestAppointmentDate = (
  appointments: AppointmentType[] = []
) => {
  if (!appointments || !appointments.length) return null;
  const updatedAppts = appointments.map((appt) => ({
    start: getNormalizedDate(appt.start),
  }));
  return updatedAppts.reduce((prev, current) =>
    prev.start > current.start ? prev : current
  ).start;
};

/**
 * Get all cycle dates from a package instance
 * */
export const getAllCycleDates = (
  packageInstance: PackageInstanceCard | PackageInstancePublic
) => {
  const { packageType } = packageInstance;

  if (!isCycleBased(packageType) || !packageInstance?.cycles) return null;

  return packageInstance.cycles;
};

/**
 * Gets the current cycle from a recurring based package
 * */
export const getCurrentCycle = (
  packageInstance?: PackageInstanceCard | PackageInstancePublic
) => {
  if (!packageInstance) return null;
  const allCycleDates = getAllCycleDates(packageInstance);
  if (!allCycleDates) return null;

  // find the current cycle based on the current date
  const todaysCycle = allCycleDates.find((item) => {
    return item.isCurrent;
  });

  return todaysCycle || null;
};

/**
 * Gets the cycle related to a specific date
 * */
export const getCycleFromDate = (
  packageInstance: PackageInstanceCard,
  date: Date
) => {
  const allCycleDates = getAllCycleDates(packageInstance);
  if (!allCycleDates) return null;

  const cycles = getAllCycleDates(packageInstance) ?? [];
  const apptCycle = cycles.find((cycle) => {
    const startDate = date;
    if (!startDate) return false;
    const normalizedStartDate = getNormalizedDate(startDate);
    const interval = Interval.fromDateTimes(
      DateTime.fromJSDate(cycle.start),
      DateTime.fromJSDate(cycle.end)
    );

    return interval.contains(DateTime.fromJSDate(normalizedStartDate));
  });
  if (!apptCycle) return null;
  return apptCycle;
};

/**
 * Calculates the initial month to display in the booking calendar
 * based on a date iso string. If it's not set, it defaults to 0.
 * */
export const calculateInitialBookMonth = (startDate?: string) => {
  if (!startDate) return 0;

  const currentDateToCompare = DateTime.fromISO(startDate).setZone("UTC");
  const now = DateTime.local().setZone("UTC");
  if (currentDateToCompare < now) return 0;

  const currentMonth = now.month;
  const packageCycleMonth = currentDateToCompare.month;
  let targetMonth = packageCycleMonth - currentMonth;

  if (currentMonth > packageCycleMonth) {
    targetMonth = 12 - currentMonth + packageCycleMonth;
  }

  return targetMonth < 0 ? 0 : targetMonth;
};

/**
 * Get the list of available and full cycles excluding past cycles
 * */
export const getFullAndAvailableCyclesFromNow = (
  packageInstance:
    | PackageInstanceCard
    | PackageInstancePublic
    | null
    | undefined
) => {
  const packageInstanceCycles = packageInstance?.cycles ?? [];
  const [packageInstanceFullCycles, packageInstanceAvailableCycles] =
    packageInstanceCycles.reduce<
      [PackageInstanceCardCyclesInner[], PackageInstanceCardCyclesInner[]]
    >(
      (agg, cycle) => {
        const isCurrentOrFutureCycle = cycle.isCurrent || cycle.isFuture;

        if (!isCurrentOrFutureCycle) return agg;

        if (cycle?.isFull) {
          agg[0].push(cycle);
        } else {
          agg[1].push(cycle);
        }
        return agg;
      },
      [[], []]
    );
  return { packageInstanceFullCycles, packageInstanceAvailableCycles };
};
